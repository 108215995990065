import Vue from 'vue'
import Vuex from 'vuex'
import tree from './tree.json'
Vue.use(Vuex)



export default new Vuex.Store({
  state: {
    step: 'start',
    tree: [],
    items: {},
    selectedItems: [],
    vacancyLevel:'',
    durationValue:'',
    workload: '',
    labour: ''

  },
  mutations: {
    setVacancyLevel: function(state, level) {
      console.log(level)
      return state.vacancyLevel = level;

    },
    changeStep: function (state, step) {
      return state.step = step;
    },
    addOthersField: function (state, tree) {
      return state.tree = tree;
    },
    items: function (state, items) {
      return state.items = items;
    },
    setSelectedItems(state, selectedItems) {
      return state.selectedItems = selectedItems;
    },
    setDurationValue(state, durationValue) {
      console.log(durationValue)
      return state.durationValue = durationValue;
    },
    setWorkload(state, workload) {
      console.log(workload)
      return state.workload = workload;
    },
    setLabour(state, labour) {
      console.log(labour)
      return state.labour = labour;
    }

  },
  actions: {
    setWorkload(ctx, workload) {
      ctx.commit('setWorkload', workload)
    },
    setLabour(ctx, labour) {
      ctx.commit('setLabour', labour)
    },
    setDurationValue(ctx, durationValue) {
      ctx.commit('setDurationValue', durationValue)
    },
    setVacancyLevel(ctx, vacancyLevel) {
      ctx.commit('setVacancyLevel', vacancyLevel)
    },
    changeStep(ctx, step) {
      ctx.commit('changeStep', step)
    },
    createTreeWithOtherField(ctx) {
      console.log('createTree')
      
        var values = []
        tree.forEach(element => {
          values.push(element)
        })
        var parentIdList = [...new Set(values.map(el => el.parent_id))]

        parentIdList.forEach(element => {
          var otherItem = {
            id: values.length + 1,
            parent_id: element,
            title: 'Other',
            sort_index: 100,
            element_type: 'LIST_ITEM',
            value: ''
          }
          values.push(otherItem)
        })

        ctx.commit('addOthersField', Object.freeze(values.filter(el => el.element_type === 'TREE_NODE')))
        var items = values.reduce((acc, el) => {
          var key = el.parent_id
          acc[key] = [...(acc[key] || []), el]
          return acc
        }, {})
        ctx.commit('items', items)

    },
    changeSelectedItems(ctx, items) {
      console.log(items)
      ctx.commit('setSelectedItems', items)    }


  },
  getters: {
    getStep: (state) => {
      return state.step;
    },
    getFullTree: (state) => {
      return state.tree;
    },
    getItems: (state) => {
      return state.items;
    },
    getSelectedItems: (state) => {
      return state.selectedItems;
    },
    getVacancyLevel: (state)  => {
      return state.vacancyLevel;
    },
    getDurationValue: (state)  => {
      return state.durationValue;
    },
    getWorkload: (state)  => {
      return state.workload;
    },
    getLabour: (state)  => {
      return state.labour;
    }   

  }
})
