<template>
  <div class="tree-content ">

    
      <div class="text-subtitle font-weight-medium mb-4 mr-auto">
        Выберите специальность:
      </div>
  
      <div class="tree-list">
        <div v-for="item in getFullTree" :key="item.id" class="tree-item">
          <div class="mx-1 my-3" ref="select" v-if="selectIsVisible(item)">
            <v-autocomplete
              
              :label="item.title"
              :items="getItems[item.id]"
              :value="checkItemInSelected(getItems[item.id])"
              item-text="title"
              item-value="(item)=>item"
              return-object
              solo
              dense
              flat
              outlined
              hide-details
              color="#00b060"
              
              @change="addItem($event, item)"
            />
            <div class="input-item  mt-6" v-show="textAreaIsVisible(item)">
              <v-text-field
                :value="textAreaValue(item)"
                placeholder="Missing category of interest? Just type it!"
                solo
                outlined
                flat
                dense
                hide-details
                color="#00b060"
                @change="addItemValue($event, item)"
              />
            </div>
          </div>
        </div>
      </div>

      <v-btn
        :disabled ="getSelectedItems.length === 0"
        :class="'ml-auto mt-6'"
        color="success" 
        elevation="2" 
        large 
        width="100px"
        @click="nextStep">
        Далее
      </v-btn>
  
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import Vue from "vue";

function clearEmptyItems(arr, result = null) {
  if (result || arr.length === 0) return arr;
  let newResult = true;
  const newArr = arr.filter((el) => {
    if (el.item_parent_id === "NULL") return true;
    const res = arr.some((otherItem) => el.item_parent_id === otherItem.id);
    if (!res) newResult = false;
    return res;
  });
  return clearEmptyItems(newArr, newResult);
}

export default {
 
  computed: {   
   
    ...mapGetters(["getSelectedItems", "getFullTree", "getItems"])
    
  },
  // watch: {
  //   getSelectedItems: function (val) {
  //     Vue.nextTick(() => {
  //       const el = this.getSelectedItems[this.$refs?.select?.length - 1];
  //       this.showNextStep =
  //         this.$refs?.select?.length === val.length ||
  //         (el && el.title === "Other" && el.value.length > 3);
  //     });
  //   }
  // },
  methods: {
    ...mapActions(["changeStep", "changeSelectedItems"]),
    nextStep() {
      this.changeStep('two')
    }, 
    checkItemInSelected(items) {
      return items.find((item) => {
        var itemInSelected = this.getSelectedItems.find(
          (selected) => selected.id === item.id
        );
        return itemInSelected;
      });
    },
    addItem(elm, parent) {
      if (elm) {
        let selectedItems = this.getSelectedItems.filter(
          (el) => el.parent_id !== parent.id
        );
        selectedItems = clearEmptyItems(selectedItems, null);
        selectedItems.push({ ...elm, item_parent_id: parent.parent_id });
        this.changeSelectedItems(selectedItems);
      }
    },
    addItemValue(what) {
      let el = this.getSelectedItems[this.getSelectedItems.length - 1];
      console.log(el);
      el = {
        ...el,
        value: what,
      };
      // this.getSelectedItems[this.getSelectedItems.length - 1] = el
      this.changeSelectedItems(el);
    },
    selectIsVisible(item) {
      return (
        this.getSelectedItems.find((el) => el.id === item.parent_id) ||
        item.parent_id === "NULL"
      );
    },
    textAreaIsVisible(item) {
      return this.getSelectedItems.find(
        (el) => el.title === "Other" && el.parent_id === item.id
      );
    },
    textAreaValue(item) {
      var otherElement = this.getSelectedItems.find(
        (el) => el.parent_id === item.id && el.title === "Other"
      );
      var textValue = "";
      if (otherElement) {
        textValue = otherElement.value;
      }
      return textValue;
    },
  },
};
</script>

<style scoped lang="scss">
  .tree-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

  }
  .tree-list {
    display: grid;
    grid-template-columns: minmax(300px, 475px)
  }
    


  // .tree-list {
  //   position: relative;
  //   display: inline;
  //   padding: 0 20px;
  //   max-width: 400px;
  //   min-width: 280px;
  //   margin: 0 auto 40px;
  //   text-align: center;

  // }
  .v-select__slot input {
      cursor: pointer
    }


</style>
