<template>
  <div class="step_four">
    <div class="text-subtitle font-weight-medium mb-4 mr-auto">
      Занятость специалиста:
    </div>

    <v-container>
      <v-radio-group v-model="value">
        <div class="r-group">
          <div v-for="item in workload" :key="item.id">
            <div class="radio_lvl_4">
              <v-radio
                :value="item.id"
                color="teal"
                on-icon="mdi-checkbox-marked-circle-outline"
                @click="setWorkload(item.id)"
              >
                <template v-slot:label>
                  <div class="ml-3">
                     <strong class="blue-grey--text text--darken-4">{{item.label}}</strong>
                     <br>
                     <small>{{item.description}}</small>
                  </div>
                </template>
              </v-radio>
            </div>
          </div>
        </div>
      </v-radio-group>
    </v-container>
    <v-btn
      :disabled = "getWorkload === ''"
      :class="'ml-auto mt-6'"
      color="success"
      elevation="2"
      large
      width="100px"
      @click="changeStep('five')"
    >
      Далее
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      value: "",
      workload: [
        {
          id: 1,
          label: "Частичная ",
          description: "2-5 часов в день"
        },
        {
          id: 2,
          label: "Полная",
          description:"8 часов в день"
        },
        {
          id: 3,
          label: "Гибкая ",
          description: "от 0 до 8 часов в день"
        },
      ],
    };
  },
  methods: {
    ...mapActions(['changeStep','setWorkload'])
  },
  computed: {
      ...mapGetters(['getWorkload'])
  }
};
</script>

<style lang="sass">
.step_four
    display: flex
    justify-content: center
     flex-direction: column
    align-items: center
    flex-wrap: wrap
.radio_lvl_4
    cursor: pointer
    max-width: 307px
    padding-left: 20px
    height: 100px
    margin: 10px
    border: 2px solid #43A047
    border-radius: 10px
    display: flex
    align-items: center
    justify-content: space-between
    position: relative
    font-style: normal
    font-weight: 500
    font-size: 22px
    line-height: 16px
    font-feature-settings: 'liga' off
    color: #388e3c


</style>