<template>
  <div class="start_app ">          
          <div class="card_text my-6 text-body-1">
            Рассчитайте стоимость часа специалиста для вашего проекта прямо
            сейчас. Для этого ответьте на&nbsp;несколько простых вопросов. 
           <br>
           <br>
            Если вам требуется сразу несколько специалистов или готовая команда, начните расчёт с&nbsp;одного из&nbsp;них, необходимость в&nbsp;котором для вас наиболее актуальна, а&nbsp;затем свяжитесь с&nbsp;нами, и&nbsp;мы&nbsp;поможем с&nbsp;расчётом для всех.
          </div>
          <div class="card_actions" >
            <v-btn color="success " :class="'ml-auto'" elevation="2" large @click="showOne()"> Рассчитать </v-btn>
          </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions(['changeStep','createTreeWithOtherField']),
    showOne() {
      this.changeStep('one')
      this.createTreeWithOtherField()

    }
  }
};
</script>

<style lang="sass">

.start_app
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap

.card_text
  text-align: justify
</style>