<template>
  <v-app>
    <v-main>

      <div class="app_container" >

        <div class="card_title mb-2 text-h5 font-weight-bold  ">  Калькулятор стоимости специалиста 
          <div v-if ="getStep !=='start' && getStep !=='end'" class="step text-subtitle-1 font-weight-medium "> {{step}} / 5 </div>
          </div> 
        <v-divider class="mb-6" ></v-divider>
        
        

          <Start v-if ="getStep ==='start'"/>
          <One v-if ="getStep ==='one'"/> 
          <Two v-if ="getStep ==='two'"/> 
          <Three v-if ="getStep ==='three'"/> 
          <Four v-if ="getStep ==='four'"/>
          <Five v-if ="getStep ==='five'"/>
          <End v-if ="getStep ==='end'"/>    
          

          <v-divider  class="my-5"></v-divider>

      </div>
 
  
    </v-main>
  </v-app>
</template>

<script>
import Start from './components/Start.vue'
import One from './components/One.vue'
import Two from './components/Two.vue'
import Three from './components/Three.vue'
import Four from './components/Four.vue'
import Five from './components/Five.vue'
import End from './components/End.vue'


import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    Start,
    One,
    Two,
    Three,
    Four,
    Five,
    End
    
  },
  computed: {
    ...mapGetters (['getStep']),
    step () {
      var text = ''
      switch (this.getStep) {
      
        case 'one': 
          text = 'Шаг 1';
          break
        case 'two': 
          text = 'Шаг 2';
          break
        case 'three': 
          text = 'Шаг 3';
          break
        case 'four': 
          text = 'Шаг 4';
          break
        case 'five': 
          text = 'Шаг 5';
          break
      }
      return text;
    }

  },
 

  data: () => ({
    //
  })
  
};
</script>

<style lang="sass">
.v-main__wrap 
  padding: 20px

.app_container
  display: flex
  background-color: #fffcf7
  flex-direction: column
  min-width: 300px

  max-width: 1100px
  justify-content: space-between
  width: 100% 
  border-radius: 4px
  box-shadow: 0px 0px 6px #666
  padding: 20px 40px
  margin: 5px auto


.step
  // margin-left: auto
  min-width: 70px
  word-wrap: normal

.card_title
  display: flex
  justify-content: space-between
  align-items: center

</style>