<template>
  <div class="end_step">
    <div v-if="!RND" class="text-subtitle font-weight-medium mb-8 mr-auto">
      В данный момент подходящих специалистов нет.
    </div>
    <div v-if="RND">
      <div class="text-subtitle font-weight-medium mb-4 mr-auto">
        Мы подобрали для вас 3-х специалистов:
      </div>
      <v-data-table :headers="headers" :items="items" :items-per-page="5">
      </v-data-table>
      <div class="text-caption font-weight-medium mb-4 mr-auto">
        * Стоимость указана ориентировочно на основе предоставленных вами
        данных.
      </div>
    </div>

    <div class="text-subtitle font-weight-medium mb-4 mr-auto">
      Получите резюме этих специалистов уже сегодня на свою электронную почту:
    </div>
    <v-col cols="8" sm="5">
      <v-text-field label="e-mail" color="success"> </v-text-field>
    </v-col>
    <v-btn
      ml-auto
      :class="'ml-auto mt-6'"
      color="success"
      elevation="2"
      large
      width="100px"
    >
      Получить
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "#",
          value: "id",
        },
        {
          text: "Специальность",
          value: "speciality",
        },
        {
          text: "Уровень",
          value: "level",
        },
        {
          text: "Доступность",
          value: "availability",
        },
        {
          text: "Стоимость за час *",
          value: "labour",
        },
      ],
      items: [
        {
          id: 1,
          speciality: "Frontend разработчик (Vue)",
          level: "middle+",
          availability: "Свободен",
          labour: 2200,
        },
        {
          id: 2,
          speciality: "Frontend разработчик (Vue)",
          level: "middle",
          availability: "Занят до 5 июля 2022",
          labour: 1700,
        },
        {
          id: 3,
          speciality: "Frontend разработчик (Vue)",
          level: "junior+",
          availability: "Освободится через 4 дня",
          labour: 1200,
        },
      ],
    };
  },
  computed: {
    RND: function () {
      return Math.floor(Math.random()*2);
    },
  },
};
</script>

<style>
</style>