<template>
  <div class="step_five">
    <div class="text-subtitle font-weight-medium mb-4 mr-auto">
      Выберите предпочтительную схему работы:
    </div>

    <v-container>
      <v-radio-group v-model="value">
        <div class="r-group_5">
          <div v-for="item in labour" :key="item.id">
            <div class="radio_lvl_5">
              <v-radio
                :value="item.id"
                color= "#4FAB68"
                on-icon="mdi-checkbox-marked-circle-outline"
                @click="setLabour(item.id)"
              >
                <template v-slot:label>
                  <div class="ml-3">
                     <strong class="blue-grey--text text--darken-4">{{item.label}}</strong>
                     <br>
                     <small v-html="item.description"> </small>
                  </div>
                </template>
              </v-radio>
            </div>
          </div>
        </div>
      </v-radio-group>
    </v-container>
    <v-btn
      :disabled = "getLabour === ''"
      :class="'ml-auto mt-6'"
      color="success"
      elevation="2"
      large
      width="100px"
      @click="changeStep('end')"
    >
      Далее
    </v-btn>
  </div>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
export default {
  data() {
    return {
      value: "",
      labour: [
        {
          id: 1,
          label: "Time and material ",
          description: "Cпециалист работает над задачами, регулярно отмечает затраченные часы, вы&nbsp;проверяете результаты и&nbsp;оплачиваете"
        },
        {
          id: 2,
          label: "Fixed price",
          description:"Cпециалист предварительно оценивает задачу, вы&nbsp;подтверждаете оценку, он&nbsp;выполняет задачу, вы&nbsp;проверяете и&nbsp;оплачиваете"
        },
        
      ],
    };
  },
  methods: {
    ...mapActions(['changeStep','setLabour']),
  },
  computed: {
  ...mapGetters(['getLabour'])
  }
  
};
</script>

<style lang="sass" scoped>
.step_five
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap

.radio_lvl_5
    cursor: pointer
    max-width: 100%
    margin: 10px
    padding: 10px 37px 10px 24px
    border: 2px solid #4FAB68
    border-radius: 10px
    display: flex
    align-items: center
    justify-content: space-between
    position: relative
    font-style: normal
    font-weight: 500
    font-size: 22px
    line-height: 16px
    font-feature-settings: 'liga' off
    color: #4FAB68
@media (min-width: 745px)
    .r-group_5
        display: grid
        grid-template-columns: 600px
        margin: 0 auto
</style>